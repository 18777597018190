/* numbers */

import { NumberFormatOptions } from "@telerik/kendo-intl";

export const einv_locale = 'es-AR';
// export const einv_locale = 'en-US';
export const appVersionNumber = "AppVersionNumber"; // Constante igual a la definida en el backend

export const currencyFieldDefaultFormat = 'c10';
// export const currencyFieldDefaultFormat = '#.0000000000';
export const currencyLabelDefaultFormat = 'c10';
export const numberFieldDefaultFormat = 'n4';
export const numberLabelDefaultFormat = 'n4';

export const einvApiDateFormat = 'YYYY-MM-DD';
export const einvApiDateFormatForFileName = 'YYYY-MM-DD-hh-mm-ss';
export const einvDateFormat = 'dd/MM/yyyy';
export const einvMomentDateFormat = 'DD/MM/YYYY';
export const einvMomentDateTimeFormat = 'DD/MM/YYYY hh:mm';

export const kendoCurrencyDefaultFormat: NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 10,
};

//Formato para campos currency en los que no se muestra mucha pre
export const kendoCurrencyLowPresitionDefaultFormat: NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 2,
};

//Formato para campos currency en los que se requiere que se muestren 2 decimales, incluso si el numero es entero
export const kendoCurrencyFixed2DecimalPositionsFormat: NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
};

export const kendoNumberDefaultFormat: NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 4,
};

//Formato para campos percentage de 2 decimales
export const kendoPercentageFixed2DecimalsFormat: NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 2,
};

//Formato para campos percentage de 1 decimal
export const kendoPercentageFixed1DecimalsFormat: NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 1,
};

//Lista de colores disponibles para graficos de torta
// export const pieChartAvailableColors: string[] = ['#fc0f03', '#35fc03', '#fc8c03', '#6ab6f7', '#696501', '#a503fc', '#030ffc', '#4a03fc', '#ccbbb8', '#b7fc97'];
export const pieChartAvailableColors: string[] = ["#1D1482","#392890","#4F3D9F","#6351AD","#7766BC","#8A7BCA","#9D91D9","#B0A7E8","#C3BEF7"];
// export const pieChartAvailableColors: string[] = ["#0077b0", "#3f7784", "#7e7758", "#bc772c", "#fb7700"];
// export const pieChartAvailableColors: string[] = ["#1d1482", "#162da1", "#0f46bf", "#075fde", "#0078fc"];

export const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

export const calculoPatrimonioNetoProcessId = 1;
export const calculoRendimientoDiarioProcessId = 2;

export const calendarYearDays = 365;
export const commercialYearDays = 360;