type JsonObjectDetailProps = {
  data?: any;
};

const JsonObjectDetail = (props: JsonObjectDetailProps) => {
  return (
    <div className="table-responsive mt-3 table-hover" style={{ overflowX: 'hidden' }}>
      <table className="table table-sm mb-0 font-13 table-hover">
        <tbody>
          {
            // for each property in the object
            Object.keys(props.data).map((key) => {
              return (
                <tr key={key} style={{ borderStyle: 'hidden' }}>
                  <td style={{ verticalAlign: 'middle' }}><strong className='me-3 long-text-ellipsis' title={key}>{key}</strong></td>
                  <td><span className="float-end text-end" title={props.data[key]}>{props.data[key]}</span></td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default JsonObjectDetail;
